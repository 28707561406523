<app-alert></app-alert>
<app-frageboegen-nicht-wiederaufschliessbar-modal *ngIf="zeigeFrageboegenNichtWiederaufschliessbarModal()"
												  [recherchekomplexid]="recherchekomplex.id"></app-frageboegen-nicht-wiederaufschliessbar-modal>
<app-recherchekomplex-beantworten-nicht-mehr-moeglich-modal *ngIf="zeigeRecherchekomplexBeantwortenNichtMehrMoeglichModal()"
															[recherchekomplex]="recherchekomplex"></app-recherchekomplex-beantworten-nicht-mehr-moeglich-modal>
<a name="frageboegen"></a>
<div class="container">
	<div class="col-md-8 col-md-offset-2 fragebogenliste">
		<a name="frageboegen_offen"></a>
		<h1>Ihre Fragebögen</h1>
		<h2>{{ recherchekomplex.name }}</h2>

		<app-ausfueller-verwaltung-dialog-button
			*ngIf="darfCoAdminsVerwalten()"
			[recherchekomplexId]="recherchekomplex.id"
			[type]="'CoAdmin'"
			class="coadmin-btn"
		>
			<span class="controls"><span class="btn btn-default btn-xs disabled" style="width: 45px; cursor: wait;">…</span></span>
		</app-ausfueller-verwaltung-dialog-button>

		<div *ngIf="recherchekomplexFrist && !showZeitraeumeInfo" class="notification-box">
			<i class="icon-info-with-circle" style="{{showSpeichernInfo ? 'top: 20px' : ''}}"></i>
			<p>{{ recherchekomplexFrist }}</p>
			<p *ngIf="showSpeichernInfo">Wir haben die Speichern-Funktion verbessert. Was das für Sie ändert, lesen Sie
				<u><strong><a routerLink="/faq" fragment="zwischenstand">hier</a></strong></u>.</p>
		</div>

		<div *ngIf="recherchekomplexFrist && showZeitraeumeInfo" class="notification-box">
			<i class="icon-info-with-circle" style="{{showZeitraeumeInfo ? 'top: 20px' : ''}}"></i>
			<p><b>Geänderte Fristen für die Übermittlung</b></p>
			<p>Wir haben die Recherchezeiträume verändert. Für die Übermittlung Ihrer Fragebögen notieren Sie sich bitte folgende Daten:</p>
			<p>{{ recherchekomplexFrist }}.</p>
			<br>
			<p *ngIf="showZeitraeumeInfo">Welche Frist für welchen Fragebogen gilt, lesen Sie
				<u><strong><a routerLink="/faq" fragment="recherchehinweise">hier</a></strong></u>.</p>
			<br>
			<p *ngIf="showZeitraeumeInfo && showSubmissionInfo">Wir haben Ihnen konkrete Tipps und Hinweise zum Ausfüllen der Fragebögen bereitgestellt. Diese finden Sie
				<u><strong><a href="/assets/portal/How-to-Submission.pdf">hier</a></strong></u>.</p>
		</div>

		<app-fragebogen-liste
			[ausfueller]="ausfueller"
			[frageboegen]="frageboegen"
			[recherchekomplexTitel]="recherchekomplex.name"
		>
		</app-fragebogen-liste>
	</div>
</div>

<div *ngIf="abgeschlosseneFrageboegen.length > 0" class="container">
	<div class="col-md-8 col-md-offset-2 fragebogenliste">
		<a name="frageboegen_abgeschlossen"></a>
		<h1>An JUVE übermittelte Fragebögen</h1>
		<ul *ngFor="let fragebogenStatus of abgeschlosseneFrageboegen" class="list-unstyled">
			<li class="abschnitt">
				<div class="text">
					<div class="title">
						<a (click)="goToFragebogen(fragebogenStatus.fragebogen)" appPreventDefault href
						>{{ fragebogenStatus.fragebogen.name }}</a>
					</div>
					<div class="description">
						Übermittelt am {{ fragebogenStatus.abschlussdatum }}
						durch {{ fragebogenStatus.abschlussGeaendertDurchUser.getNameMitDativAnrede() }}
					</div>
				</div>

				<div class="buttons-right">
					<a (click)="goToFragebogen(fragebogenStatus.fragebogen)" appPreventDefault class="btn btn-secondary" type="button"
					>Zum Fragebogen</a>
				</div>
			</li>
		</ul>
	</div>
</div>

